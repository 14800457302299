import React from "react"
import styled from "styled-components"

const StyledButton = styled.button`
  font-size: 1.4rem;
  padding: 1rem 2rem;
  background: ${props => props.bg};
  color: ${props => props.color};
  transition: 200ms ease-in-out;
  border: none;
  font-weight: 500;
  /* text-transform: uppercase; */
  cursor: pointer;
  border-radius: 5px;
  .inner {
    /* opacity: 0.7; */
    align-items: center;
    position: relative;
    span {
      height: 100%;
      display: flex;
      align-items: center;

      position: absolute;
      right: -1rem;
      top: 0;
      margin: 0;
      img {
        width: 40px;
        filter: invert(1);
      }
    }
  }
  &:hover {
    filter: invert(1);
  }
  &:focus {
    outline: none;
  }
`

const Button = props => {
  return (
    <StyledButton
      className={props.className}
      onClick={props.onClick}
      id={props.id}
      color={props.color}
      bg={props.bg}
      disabled={props.disabled}
      type={props.type}
    >
      <div className="inner">
        {props.children}
        {props.icon ? (
          <span>
            <img src={props.icon} alt="" />
          </span>
        ) : null}
      </div>
    </StyledButton>
  )
}

export default Button
