import React from "react"
import styled from "styled-components"
import Navbar from "./navbar"
import Footer from "./footer"
import "../utils/index.css"
import { colors } from "../utils/siteVars"
import { Helmet } from "react-helmet"
import BackgroundImage from "gatsby-background-image"

const StyledLayout = styled.div`
  background: ${colors.dark};
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  min-height: 100vh;
  .app-inner {
    min-height: 100vh;
    flex-grow: 1;
    padding: calc(55px + 1rem) 1rem 1rem 1rem;
  }
`

const Layout = props => {
  return (
    <>
      <StyledLayout>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700&display=swap"
            rel="stylesheet"
          />

          <title>{`${props.title} | maybeTshirt.com`}</title>
          <meta
            name="description"
            content="Create your own custom t-shirt in just a few clicks!"
          ></meta>
          <link
            rel="apple-touch-icon"
            sizes="57x57"
            href="/favicon/apple-icon-57x57.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="60x60"
            href="/favicon/apple-icon-60x60.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="72x72"
            href="/favicon/apple-icon-72x72.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="76x76"
            href="/favicon/apple-icon-76x76.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="114x114"
            href="/favicon/apple-icon-114x114.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="120x120"
            href="/favicon/apple-icon-120x120.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href="/favicon/apple-icon-144x144.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="152x152"
            href="/favicon/apple-icon-152x152.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/favicon/apple-icon-180x180.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href="/favicon/android-icon-192x192.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="96x96"
            href="/favicon/favicon-96x96.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon/favicon-16x16.png"
          />
          <link rel="manifest" href="/favicon/manifest.json" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
          <meta name="theme-color" content="#ffffff"></meta>
        </Helmet>
        <Navbar />
        <BackgroundImage fluid={props.bg}>
          <div className="app-inner">{props.children}</div>
        </BackgroundImage>
      </StyledLayout>
      <Footer />
    </>
  )
}

export default Layout
