import React from "react"
import styled from "styled-components"
import { colors } from "../utils/siteVars"

const StyledFooter = styled.footer`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4rem 1rem 2rem 1rem;
  color: ${colors.white};
  background: ${colors.black};
  p {
    margin: 0;
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      <p>maybetshirt.com</p>
      <a href="mailto:sales@maybetshirt.com">sales@maybetshirt.com</a>
    </StyledFooter>
  )
}

export default Footer
