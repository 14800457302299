import React, { useState } from "react"
import styled from "styled-components"
import Hamburger from "./hamburger"
import { colors } from "../utils/siteVars"
import BtnNoStyle from "./btnNoStyle"
import { Link } from "gatsby"

const StyledNavbar = styled.nav`
  position: fixed;
  top: 0;
  display: flex;
  z-index: 10;
  width: 100%;
  color: ${colors.dark};
  .nav-inner {
    position: relative;
    z-index: 2;
    padding: 1rem;
    height: 55px;
    background: ${colors.primary};

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    .nav-left {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        object-fit: contain;
        margin-right: 1rem;
      }
      h3 {
        color: ${colors.white};
        span {
          color: ${colors.secondary};
          &:nth-child(3) {
            color: ${colors.dark};
          }
        }
      }
    }
  }
  .nav-dropdown {
    padding-top: calc(55px + 1rem);
    position: absolute;
    top: -100vh;
    left: 0;
    right: 0;
    background: ${colors.secondary};
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 1;
    padding-bottom: 1rem;
    transition: 300ms ease-in-out;
    li {
      text-transform: uppercase;
      padding: 0.5rem;
    }
    &.active {
      top: 0;
    }
  }
  .nav-dropdown {
    display: none;
  }
  .nav-right-mobile {
    display: none;
  }
  .nav-right-desktop {
    display: block;
    ul {
      display: flex;
      li {
        text-transform: uppercase;
        color: ${colors.white};
        padding: 0.5rem;
        transition: 200ms ease-in-out;
        position: relative;
        top: 0;
        &:hover {
          top: 0.3rem;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .nav-dropdown {
      display: flex;
    }
    .nav-right-mobile {
      display: block;
    }
    .nav-right-desktop {
      display: none;
    }
  }
`

const Navbar = () => {
  const [ddOpen, setDdOpen] = useState(false)

  const logoSection = (
    <div className="nav-left">
      <Link to="/">
        <img src="/images/MBT-logo_2.png" alt="logo" />
      </Link>
      <Link to="/">
        <h3>
          MAYBE<span>TSHIRT</span>
          <span>.</span>
          <span>COM</span>
        </h3>
      </Link>
    </div>
  )

  const links = [
    {
      text: "Make a tshirt",
      link: "/make",
    },
    {
      text: "Browse designs",
      link: "/browse",
    },
    {
      text: "Contact",
      link: "/contact",
    },
  ]

  const ul = (
    <ul>
      {links.map(link => (
        <Link to={link.link} key={link.link}>
          <li>{link.text}</li>
        </Link>
      ))}
    </ul>
  )

  return (
    <StyledNavbar>
      <div className="nav-inner">
        {logoSection}
        <div className="nav-right-mobile">
          <BtnNoStyle
            onClick={() => {
              setDdOpen(!ddOpen)
            }}
          >
            <Hamburger color={colors.dark} active={ddOpen} />
          </BtnNoStyle>
        </div>
        <div className="nav-right-desktop">{ul}</div>
      </div>
      <div className={`nav-dropdown ${ddOpen ? "active" : ""}`}>{ul}</div>
    </StyledNavbar>
  )
}

export default Navbar
