export const colors = {
  white: "#FFF",
  offWhite: "#f2f2f2",
  mediumGrey: "#c7c7c7",
  primary: "#ED254E",
  secondary: "#F9DC5C",
  dark: "#011936",
  black: "#000",
}

const MAIN_URI = "https://maybetshirt.com"
// const MAIN_URI = "http://localhost:8000"
const DB_URI = "https://maybetshirt.netlify.app:9000"
// const DB_URI = "http://localhost:9000"
export const db = `${DB_URI}/.netlify/functions/index/`
export const successURL = `${MAIN_URI}/success`
export const cancelURL = `${MAIN_URI}/cancelled`
